import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const resources = {
  pl: {
    translation: {
      "Główna": "Główna",
      "Blog": "Blog",
      "O mnie": "O mnie",
      "Kontakt": "Kontakt",
      "Oferta": "Oferta",

      "Polityka prywatności": "Polityka prywatności",
      "Polityka Cookies": "Polityka Cookies",
      "Regulamin": "Regulamin",

      "Formularz kontaktowy": "Formularz kontaktowy",
      "Warszawa, Białołęka, Tarchomin": "Warszawa, Białołęka, Tarchomin",

      "Tłumacz przysięgły języka ukraińskiego": "Tłumacz przysięgły języka ukraińskiego",
      "Michalina Zgódka-Bilous": "Michalina Zgódka-Bilous",
      "Świderska 102/18": "Świderska 102/18",
      "Zobacz w Mapach Google": "Zobacz w Mapach Google",

      "label.name": "Imię i nazwisko",
      "label.email": "Adres email",
      "label.phone": "Numer telefonu (polski)",
      "label.message": "Wiadomość",
      "label.files": "Załączniki",
      "label.terms": "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Michalinę Zgódkę-Bilous TP, ul. Świderska 102/18, 03-128 Warszawa w celu i w zakresie niezbędnym do realizacji obsługi niniejszego zgłoszenia. Zapoznałem się z treścią informacji o sposobie przetwarzania danych osobowych umieszczonych w",
      "label.termsLink": "załączniku",
      "label.submit": "Wyślij",
      "label.submitting": "Wysyłanie",

      "placeholder.name": "np. Paweł Nowak",
      "placeholder.email": "pnowak@mail.com",
      "placeholder.phone": "+48123456789",
      "placeholder.message": "Proszę o wycenę zamówienia",

      "dropzone.title": "Przeciągnij pliki lub kliknij żeby wybrać",
      "dropzone.subtitle": "Można dołączyć maksymalnie {{maxFiles}} plików o rozmiarze nie większym niż {{maxSize}}Mb",
      "dropzone.accept": "Odpuść plik, żeby go dodać",
      "dropzone.reject": "Dany plik nie jest poprawny",
      "dropzone.listLabel": "Dołączone pliki",
      "dropzone.remove": "Usuń plik",

      "error.fileAlreadyExists": "Plik {{file}} już jest dodany do listy",
      "error.invalidType": "Plik {{file}} ma niepoprawne rozszerzenie",
      "error.maxFiles": "Można dodać maksymalnie {{max}} plików",
      "error.failedToAddFile": "Nie udało się dodać pliku {{file}}",
      "error.fileTooLarge": "Plik {{file}} nie został dodany. Maksymalny rozmiar pliku - {{max}}Mb",

      "error.name.required": "Imię jest wymagane",
      "error.name.max": "Imię nie może być dłuższe niż {{max}} znaków",
      "error.email.invalid": "Email nie jest prawidłowy",
      "error.email.max": "Email nie może być dłuższy niż {{max}} znaków",
      "error.email.required": "Email jest wymagany",

      "error.phone.required": "Numer telefonu jest wymagany",
      "error.phone.match": "Numer telefonu może zawierać tylko cyfry, spacje oraz znaki ( ) - +",

      "error.message.required": "Pole wiadomości jest wymagane",
      "error.message.max": "Maksymalna długość wiadomości {{max}} znaków",

      "error.terms.required": "Wyrażenie zgody jest wymagane",
      "request.failed": "Nie udało się wysłać wiadomości",
      "request.success": "Wiadomość została wysłana",

      "home.title": "Tłumacz przysięgły języka ukraińskiego - Michalina Zgódka-Bilous",
      "home.description": "Tłumacz przysięgły języka ukraińskiego",
      "home.keywords": "tłumacz, ukraiński, przysięgły",

      "offer.title": "Oferta - Tłumacz przysięgły języka ukraińskiego - Michalina Zgódka-Bilous",
      "offer.description": "Tłumacz przysięgły języka ukraińskiego",
      "offer.keywords": "tłumacz, ukraiński, przysięgły",

      "contact.title": "Oferta - Tłumacz przysięgły języka ukraińskiego - Michalina Zgódka-Bilous",
      "contact.description": "Tłumacz przysięgły języka ukraińskiego",
      "contact.keywords": "tłumacz, ukraiński, przysięgły",

      "about.title": "Tłumacz przysięgły języka ukraińskiego - Michalina Zgódka-Bilous",
      "about.description": "Tłumacz przysięgły języka ukraińskiego",
      "about.keywords": "tłumacz, ukraiński, przysięgły",

      "formAlertTitle": "Uwaga!",
      "formAlertMessage": "W dniach 02.12-14.12 2023 przebywam na urlopie.",
    }
  },
  uk: {
    translation: {
      "Główna": "Головна",
      "Blog": "Блог",
      "O mnie": "Про мене",
      "Kontakt": "Зворотній зв'язок",
      "Oferta": "Послуги",

      "Polityka prywatności": "Політика конфіденційності",
      "Polityka Cookies": "Політика Cookies",
      "Regulamin": "Правила користування",

      "Formularz kontaktowy": "Форма зворотнього зв'язку",
      "Warszawa, Białołęka, Tarchomin": "Варшава, Бялоленка, Тархомін",

      "Tłumacz przysięgły języka ukraińskiego": "Присяжний перекладач української мови",
      "Michalina Zgódka-Bilous": "Міхаліна Зґудка-Білоус",
      "Świderska 102/18": "Швідерська 102/18",
      "Zobacz w Mapach Google": "Відкрити в Картах Google",

      "label.name": "Ім'я і прізвище",
      "label.email": "Адреса email",
      "label.phone": "Номер телефону (польський)",
      "label.message": "Повідомлення",
      "label.files": "Вкладені файли",
      "label.terms": "Я даю свою згоду на обробку моїх персональних даних присяжним перекладачем Міхаліною Зґудкою-Білоус (Michalina Zgódka-Bilous TP), зареєстрований офіс вул. Швідерська, будинок 102 приміщення 18, 03-128 Варшава, з метою і в обсязі необхідному для обслуговування цього повідомлення. Мені відомий зміст інформації про спосіб обробки персональних даних, вказаних в",
      "label.termsLink": "додатку",
      "label.submit": "Вислати повідомлення",
      "label.submitting": "Висилання",

      "placeholder.name": "напр. Андрій Мельник",
      "placeholder.email": "amelnyk@mail.com",
      "placeholder.phone": "+48123456789",
      "placeholder.message": "Будь ласка оцініть вартість перекладу вкладених файлів",

      "dropzone.title": "Перетягни або натисни щоб додати файли",
      "dropzone.subtitle": "Можна додати максимум {{maxFiles}} файлів розміром не більше {{maxSize}}Мб кожен",
      "dropzone.accept": "Відпусти файл щоб додати",
      "dropzone.reject": "Цей файл не підходить",
      "dropzone.listLabel": "Додані файлт",
      "dropzone.remove": "Видалити файл",

      "error.fileAlreadyExists": "Файл {{file}} вже доданий до списку",
      "error.invalidType": "Файл {{file}} має неправильний тип",
      "error.maxFiles": "Можна додати максимум {{max}} файлів",
      "error.failedToAddFile": "Не вдалося додати файлу {{file}}",
      "error.fileTooLarge": "Файл {{file}} не додався. Максимальний розмір файлу - {{max}}Мб",

      "error.name.required": "Це поле є обов'язковим",
      "error.name.max": "Поле не може містити більше ніж {{max}} знаків",
      "error.email.invalid": "Адрес email неправильний",
      "error.email.max": "Поле не може містити більше ніж {{max}} знаків",
      "error.email.required": "Це поле є обов'язковим",

      "error.phone.required": "Це поле є обов'язковим",
      "error.phone.match": "В цьому полі можуть бути тільки цифри, пробіли і наступні знаки ( ) - +",

      "error.message.required": "Це поле є обов'язковим",
      "error.message.max": "Максимальна довжина повідомлення - {{max}} знаків",

      "error.terms.required": "Це поле є обов'язковим",
      "request.failed": "Не вдалось вислати повідомлення",
      "request.success": "Повідомлення успішно відправлено",

      "home.title": "Присяжний перекладач української мови - Міхаліна Зґудка-Білоус",
      "home.description": "Присяжний перекладач української мови - Міхаліна Зґудка-Білоус",
      "home.keywords": "перекладач, українська, присяжний",

      "offer.title": "Послуги - Присяжний перекладач української мови - Міхаліна Зґудка-Білоус",
      "offer.description": "Послуги присяжного перекладача української мови - Міхаліни Зґудки-Білоус",
      "offer.keywords": "перекладач, українська, присяжний",

      "contact.title": "Зворотній зв'язок - Присяжний перекладач української мови - Міхаліна Зґудка-Білоус",
      "contact.description": "Зворотній зв'язок з присяжним перекладачем української мови - Міхаліною Зґудкою-Білоус",
      "contact.keywords": "перекладач, українська, присяжний",

      "about.title": "Про мене - Присяжний перекладач української мови - Міхаліна Зґудка-Білоус",
      "about.description": "Про присяжного перекладача української мови Міхаліну Зґудку-Білоус",
      "about.keywords": "перекладач, українська, присяжний",

      "formAlertTitle": "Увага!",
      "formAlertMessage": "Від 02 грудня до 14 грудня 2023 року я у відпустці.",
    }
  }
};

i18n
.use(LanguageDetector)
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  resources,
  detection: {
    order: ['path','htmlTag', 'navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'subdomain'],
  },
  languages: ['pl', 'uk'],
  defaultLocale: 'pl',
  locales: ['pl', 'uk'],
  nsSeparator: false,
  keySeparator: false,
  // lng: "pl", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  // if you're using a language detector, do not define the lng option

  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;
