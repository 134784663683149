import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import {useSSR} from "react-i18next";
import {resources} from "./i18n";
import { loadableReady } from '@loadable/component'

function AppWithTranslations() {
  useSSR(resources, window.locale);
  return <App />
}

loadableReady(() => {
  const root = document.getElementById('root')
  hydrate((
    <BrowserRouter>
      <AppWithTranslations />
    </BrowserRouter>
  ), root)
})


if (module.hot) {
  module.hot.accept();
}
