import React from 'react';
import loadable from '@loadable/component'
import { Route, Switch } from 'react-router-dom';
import './App.css';
import i18n from './i18n';
import './styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';
import {I18nextProvider} from "react-i18next";
import {ToastContainer} from "react-toastify";

const Navbar = loadable(() => import("./Navbar"));
const Layout = loadable(() => import("./components/Layout/Layout"));
const About = loadable(() => import("./pages/About"));
const Offer = loadable(() => import("./pages/Offer"));
const PrivatePolicy = loadable(() => import("./pages/PrivatePolicy"));
const Rules = loadable(() => import("./pages/Rules"));
const CookiesPolicy = loadable(() => import("./pages/CookiesPolicy"));
const Contact = loadable(() => import("./pages/Contact"));
const Home = loadable(() => import("./pages/Home"));


import {Helmet} from "react-helmet";

const base = '/:locale(pl|uk)?';

const LocaleRoute = ({ component: Component, ...rest }) => {
  const {computedMatch: {params: {locale}}} = rest;
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return (
          <Layout>
            <Component locale={locale || 'pl'} {...routeProps} />
          </Layout>
        );
      }}
    />
  )}

const App = () => (
  <I18nextProvider i18n={i18n}>
    <Helmet>
      <title>{i18n.t('home.title')}</title>
      <meta name="description" content={i18n.t('home.description')} />
      <meta name="keywords" content={i18n.t('home.keywords')} />
    </Helmet>
    <div>
      <Navbar />
      <Switch>
        <LocaleRoute exact path={base} component={Home} />
        <LocaleRoute path={`${base}/about`} component={About} />
        <LocaleRoute path={`${base}/offer`} component={Offer} />
        <LocaleRoute path={`${base}/contact`} component={Contact} />
        <LocaleRoute path={`${base}/private-policy`} component={PrivatePolicy} />
        <LocaleRoute path={`${base}/cookies-policy`} component={CookiesPolicy} />
        <LocaleRoute path={`${base}/rules`} component={Rules} />
      </Switch>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>

  </I18nextProvider>
);

export default App;
